<template>
  <div>
    <BreadCrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />

    <v-card class="mt-2">
      <v-card-title> Editar grupos de usuários </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              readonly
              label="Nome"
              v-model="form.group_name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              hide-details
              readonly
              label="Código"
              v-model="form.group_code"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h2>Funcionalidades</h2>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row
          class="mt-3"
          v-bind:key="systemArea.id"
          v-for="systemArea in groupAndFeatures"
        >
          <v-col>
            <h3>{{ systemArea.name }}</h3>

            <!-- grupos de funcionalidades -->
            <div
              class="ml-2"
              v-bind:key="group.id"
              v-for="group in systemArea.childs"
            >
              <v-row v-if="group.features.length">
                <v-col>
                  <h4>{{ group.name }}</h4>

                  <!-- funcionalidades do grupo -->
                  <v-row
                    class="ml-4 checkbox-row"
                    no-gutters
                    v-bind:key="feature.id"
                    v-for="feature in group.features"
                  >
                    <v-checkbox
                      v-model="selectedFeaturesUserGroups"
                      :label="feature.name"
                      :value="feature.id"
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              small
              class="primary darken-1 ml-2 mb-2"
              @click="save()"
              :loading="loading"
            >
              <v-icon left>mdi-content-save</v-icon> Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false"
            >Ok</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";

export default {
  components: {
    BreadCrumbs,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      successDialog: false,
      form: {},
      groupAndFeatures: [],
      selectedFeaturesUserGroups: [],
    };
  },
  methods: {
    async loadUserGroupsData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/admin/usuarios/grupos/${this.groupId}`
        );

        this.setUserGroupsData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setUserGroupsData(data) {
      const cpData = { ...data };

      this.form = cpData.Group;
      this.groupAndFeatures = cpData.groupAndFeatures;
      this.selectedFeaturesUserGroups = cpData.featuresUserGroups;
    },
    validateForm() {
      const errors = [];

      if (!this.form.group_name) errors.push("O campo NOME é obrigatório");

      if (!this.form.group_code) errors.push("O campo CÓDIGO é obrigatório");

      if (!this.selectedFeaturesUserGroups.length)
        errors.push("Selecione ao menos uma funcionalidade");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async update() {
      try {
        this.loading = true;

        let dataToSend = this.formatDataToSend();

        await this.$axios.put(
          `/admin/usuarios/grupos/${this.groupId}`,
          dataToSend
        );

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatDataToSend() {
      return {
        features: this.selectedFeaturesUserGroups,
        ...this.form,
      };
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    if (this.mode === "update") this.loadUserGroupsData();
  },
};
</script>

<style scoped>
.checkbox-row {
  padding: 0;
}

.v-input--selection-controls {
  margin-top: 0;
}
</style>
